<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
var _hmt = _hmt || []
;(function () {
  var hm = document.createElement('script')
  hm.src = 'https://hm.baidu.com/hm.js?6f13db2b22ce01ee94c7c59bb83ca968'
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(hm, s)
})()

function isMobile() {
  let flag =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  return flag
}
if (isMobile()) {
  location.href = 'http://www.tandidi.com/m/'
}

console.log(location.href, 'window.location.href')
</script>

<style lang="less" scoped>
#app {
  font-family: 'Noto Sans SC', Arial, Helvetica, Arial, sans-serif;
  color: #fff;
  margin: 0;
  padding: 0;
}
</style>
