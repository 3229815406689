import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/meetingCarbon',
    name: 'meetingCarbon',
    component: () => import('@/views/meetingCarbon.vue'),
  },
  {
    path: '/enterpriseCarbon',
    name: 'enterpriseCarbon',
    component: () => import('@/views/enterpriseCarbon.vue'),
  },
  {
    path: '/exhibitionCarbon',
    name: 'exhibitionCarbon',
    component: () => import('@/views/exhibitionCarbon.vue'),
  },
  {
    path: '/synergyCarbon',
    name: 'synergyCarbon',
    component: () => import('@/views/synergyCarbon.vue'),
  },
  {
    path: '/productCarbon',
    name: 'productCarbon',
    component: () => import('@/views/productCarbon.vue'),
  },
  {
    path: '/reNewing',
    name: 'reNewing',
    component: () => import('@/views/reNewing.vue'),
  },
  {
    path: '/netProduct',
    name: 'netProduct',
    component: () => import('@/views/netProduct.vue'),
  },
  {
    path: '/newsTrends',
    name: 'newsTrends',
    component: () => import('@/views/newsTrends.vue'),
  },
  {
    path: '/aboutMe',
    name: 'aboutMe',
    component: () => import('@/views/aboutMe.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',

  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition)
    return { x: 0, y: 0 }
  },
})

export default router
