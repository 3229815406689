<template>
  <div>
    <img
      @click="routerClick({ name: 'home' })"
      src="@/assets/images/home/home-logo.png"
      alt=""
      style="
        cursor: pointer;
        width: 128px;
        height: 36px;
        position: absolute;
        top: 5%;
        left: 10%;
        z-index: 999;
      "
    />
    <!-- <div style="cursor: pointer; position: absolute; top: 5%; right: 15%">
      <span style="margin-right:10px">简</span>/<span style="margin-left:10px">En</span>
    </div> -->
    <img
      @click="routerButtonable = true"
      src="@/assets/images/home/router-button.png"
      alt=""
      style="
        cursor: pointer;
        width: 36px;
        height: 32px;
        opacity: 0.8;
        position: absolute;
        top: 5%;
        right: 10%;
        z-index: 999;
      "
    />
    <el-drawer :visible.sync="routerButtonable" size="25%" :show-close="false">
      <img
        src="@/assets/images/home/router-logo.png"
        @click="routerClick({ name: 'home' })"
        alt=""
        style="
          cursor: pointer;
          width: 120px;
          position: absolute;
          left: 18%;
          top: 40px;
          z-index: 999;
        "
      />
      <img
        @click="routerButtonable = false"
        src="@/assets/images/home/router-close.png"
        alt=""
        style="
          width: 40px;
          position: absolute;
          right: 10%;
          top: 38px;
          cursor: pointer;
          z-index: 999;
        "
      />
      <ul class="routerUl">
        <li @click="routerClick({ name: 'meetingCarbon' })">会议/活动碳中和</li>
        <li @click="routerClick({ name: 'enterpriseCarbon' })">
          企业/机构碳中和
        </li>
        <li @click="routerClick({ name: 'exhibitionCarbon' })">展会碳中和</li>
        <li @click="routerClick({ name: 'synergyCarbon' })">协同低碳</li>
        <li @click="routerClick({ name: 'productCarbon' })">产品碳中和</li>
        <li @click="routerClick({ name: 'reNewing' })">减排量和可再生产品</li>
        <li @click="routerClick({ name: 'netProduct' })">
          碳低链及NFT产品发行
        </li>
        <li @click="routerClick({ name: 'newsTrends' })">新闻动态</li>
        <li style="display:none;" @click="routerClick({ name: 'aboutMe' })">关于我们</li>
      </ul>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: 'NavButton',

  data() {
    return {
      routerButtonable: false, //路由按钮
    }
  },
  methods: {
    //公司新闻行业新闻切换
    newsbuttonClick(index) {
      this.buttonindex = index
    },
    routerClick(name) {
      this.$router.push(name)
    },
  },
}
</script>

<style lang="less" scoped>
.routerButtonable {
  position: absolute;
  top: 0;
  right: 0;
  width: 310px;
  background: rgba(255, 255, 255, 0.39);
  opacity: 1;
  z-index: 999;
}
.routerUl {
  margin-left: 20%;
  margin-top: 20%;
  list-style: none;
  li {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    opacity: 1;
    cursor: pointer;
    &:hover {
      color: #34a994;
    }
  }
}
</style>
