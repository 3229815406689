<template>
  <div id="home" style="min-width: 1200px; overflow-x: hidden">
    <div class="home-bigimg" style="">
      <NavButton style="z-index: 999"></NavButton>

      <el-carousel
        :interval="4000"
        :height="bannerHeight + 'px'"
        style="overflow: hidden"
      >
        <el-carousel-item
          v-for="item in homeList"
          :key="item.id"
          class="home-carousel"
          @click.native="routerClick({ name: item.url })"
        >
          <img
            :src="item.src"
            alt=""
            style="width: 100%; position: relative"
            ref="bannerHeight"
            @load="imgLoad"
          />
          <h4
            v-html="item.title"
            :class="item.id == 1 ? 'left10' : 'text'"
          ></h4>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="home-card" style="display: flex; margin-top: 20px">
      <div style="position: relative; width: 50%; margin-left: 20px">
        <img
          src="@/assets/images/home/home-card1.jpg"
          alt=""
          style="width: 100%"
        />
        <div class="card-text flex-column-center">
          <h3>会议/活动碳中和</h3>
          <button
            class="card-button flex-center"
            @click="routerClick({ name: 'meetingCarbon' })"
          >
            了解更多
            <img
              src="@/assets/images/home/arrows-right.png"
              alt=""
              style="width: 15px; height: 15px; margin-left: 10px"
            />
          </button>
        </div>
      </div>
      <div
        style="
          position: relative;
          width: 50%;
          margin-left: 20px;
          margin-right: 20px;
        "
      >
        <img
          src="@/assets/images/home/home-card2.jpg"
          alt=""
          style="width: 100%"
        />
        <div class="card-text flex-column-center">
          <h3>企业/机构碳中和</h3>
          <button
            class="card-button flex-center"
            @click="routerClick({ name: 'enterpriseCarbon' })"
          >
            了解更多
            <img
              src="@/assets/images/home/arrows-right.png"
              alt=""
              style="width: 15px; height: 15px; margin-left: 10px"
            />
          </button>
        </div>
      </div>
    </div>
    <div
      style="
        position: relative;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
      "
    >
      <img
        src="@/assets/images/home/home-card3.png"
        alt=""
        style="width: 100%"
      />
      <div class="card-text flex-column-center" style="width: 40%; left: 30%">
        <h3>展会碳中和</h3>
        <button
          class="card-button flex-center"
          @click="routerClick({ name: 'exhibitionCarbon' })"
        >
          了解更多
          <img
            src="@/assets/images/home/arrows-right.png"
            alt=""
            style="width: 15px; height: 15px; margin-left: 10px"
          />
        </button>
      </div>
    </div>
    <div style="margin-top: 60px">
      <el-carousel
        :interval="2000"
        type="card"
        height=" 600px"
        indicator-position="none"
      >
        <el-carousel-item v-for="item in carouselist" :key="item.id">
          <div style="position: relative">
            <img :src="item.src" alt="" style="width: 100%; height: 100%" />
            <div class="card-text flex-column-center">
              <h3>{{ item.title }}</h3>
              <button
                class="card-button flex-center"
                @click="routerClick({ name: 'meetingCarbon' })"
              >
                了解更多
                <img
                  src="@/assets/images/home/arrows-right.png"
                  alt=""
                  style="width: 15px; height: 15px; margin-left: 10px"
                />
              </button>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div style="display: flex; margin-top: 20px">
      <div style="width: 50%" class="home-product flex-column-center">
        <div>
          <h1>碳低链及NFT产品发行</h1>
          <p>
            碳低链是由复旦大学绿色金融研究中心、中国环博会、上海市环境科学研究院、中科院广州能源所、上海环境能源交易所、碳抵科技等作为共同发起节点单位联合发起的，以碳中和数字化、低碳NFT发行为核心能力的联盟区块链。
          </p>
          <button
            class="card-button flex-center"
            @click="routerClick({ name: 'netProduct' })"
          >
            了解更多
            <img
              src="@/assets/images/home/arrows-right.png"
              alt=""
              style="width: 15px; height: 15px; margin-left: 10px"
            />
          </button>
        </div>
      </div>
      <img
        src="@/assets/images/home/home-produc.png"
        alt=""
        style="width: 50%"
      />
    </div>
    <div style="margin-bottom: 60px; position: relative; margin-top: -40px">
      <img
        src="@/assets/images/home/home-newsback.jpg"
        alt=""
        style="width: 100%"
      />
      <div
        style="
          position: absolute;
          top: 20%;
          left: 20%;
          width: 60%;
          right: 20%;
          height: 60%;
          bottom: 20%;
        "
      >
        <div
          style="
            border-bottom: 1px solid #999999;
            width: 100%;
            position: relative;
            padding-bottom: 20px;
          "
        >
          <span
            v-for="(item, index) in newsbutton"
            :key="item.id"
            @click="newsbuttonClick(index)"
            :class="buttonindex == index ? 'activebutton' : 'newsbutton'"
            style="margin-left: 40px; cursor: pointer"
            >{{ item.name }}</span
          >
          <span
            @click="routerClick({ name: 'newsTrends' })"
            class="flex-center"
            style="
              position: absolute;
              right: 0;
              bottom: 20px;
              font-size: 20px;
              font-weight: 400;
              color: #3bb29c;
              opacity: 1;
              cursor: pointer;
            "
          >
            查看更多
            <img
              src="@/assets/images/aboutMe/button-right.png"
              alt=""
              style="width: 20px; height: 20px; margin-left: 10px"
            />
          </span>
        </div>
        <div v-if="buttonindex == 0" style="display: flex; height: 100%">
          <div class="newsbox" @click="contentToWx(0)">
            <img :src="contentList[0].img_url" alt="" class="newsbox-img" />
            <div class="newsbox-content">
              <div class="dateStr">
                <img src="@/assets/images/home/home-time.png" alt="" />
                <span>{{ contentList[0].dateStr }}</span>
              </div>
              <h3>{{ contentList[0].title }}</h3>
              <p>{{ contentList[0].desc }}</p>
            </div>
          </div>
          <div class="newsbox" @click="contentToWx(1)" style="margin-left: 2%">
            <img :src="contentList[1].img_url" alt="" class="newsbox-img" />
            <div class="newsbox-content">
              <div class="dateStr">
                <img src="@/assets/images/home/home-time.png" alt="" />
                <span>{{ contentList[1].dateStr }}</span>
              </div>
              <h3>{{ contentList[1].title }}</h3>
              <p>{{ contentList[1].desc }}</p>
            </div>
          </div>
          <div class="newsbox" @click="contentToWx(2)" style="margin-left: 2%">
            <img :src="contentList[2].img_url" alt="" class="newsbox-img" />
            <div class="newsbox-content">
              <div class="dateStr">
                <img src="@/assets/images/home/home-time.png" alt="" />
                <span>{{ contentList[2].dateStr }}</span>
              </div>
              <h3>{{ contentList[2].title }}</h3>
              <p>{{ contentList[2].desc }}</p>
            </div>
          </div>
        </div>
        <div v-else style="display: flex; height: 100%">
          <div class="newsbox" @click="contentToWx(0)">
            <img :src="contentList[0].img_url" alt="" class="newsbox-img" />
            <div class="newsbox-content">
              <div class="dateStr">
                <img src="@/assets/images/home/home-time.png" alt="" />
                <span>{{ contentList[0].dateStr }}</span>
              </div>
              <h3>{{ contentList[0].title }}</h3>
              <p>{{ contentList[0].desc }}</p>
            </div>
          </div>
          <div class="newsbox" @click="contentToWx(1)" style="margin-left: 2%">
            <img :src="contentList[1].img_url" alt="" class="newsbox-img" />
            <div class="newsbox-content">
              <div class="dateStr">
                <img src="@/assets/images/home/home-time.png" alt="" />
                <span>{{ contentList[1].dateStr }}</span>
              </div>
              <h3>{{ contentList[1].title }}</h3>
              <p>{{ contentList[1].desc }}</p>
            </div>
          </div>
          <div class="newsbox" @click="contentToWx(2)" style="margin-left: 2%">
            <img :src="contentList[2].img_url" alt="" class="newsbox-img" />
            <div class="newsbox-content">
              <div class="dateStr">
                <img src="@/assets/images/home/home-time.png" alt="" />
                <span>{{ contentList[2].dateStr }}</span>
              </div>
              <h3>{{ contentList[2].title }}</h3>
              <p>{{ contentList[2].desc }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-column-center">
      <h1
        style="
          font-size: 38px;
          font-weight: 600;

          color: #333333;
          opacity: 1;
        "
      >
        合作伙伴
      </h1>
      <img
        src="@/assets/images/home/home-partnter.png"
        alt=""
        style="width: 60%; margin-top: 40px"
      />
    </div>
    <BottomNav></BottomNav>
  </div>
</template>

<script>
import NavButton from '@/components/navButton'
import BottomNav from '@/components/bottomNav'
import axios from 'axios'

export default {
  name: 'HomeView',
  components: { NavButton, BottomNav },

  data() {
    return {
      bannerHeight: '',
      carouselist: [
        {
          id: 1,
          src: require('@/assets/images/home/home-carouse1.png'),
          title: '产品碳中和',
        },
        {
          id: 2,
          src: require('@/assets/images/home/home-carouse2.png'),
          title: '减排量和可再生产品',
        },
        {
          id: 3,
          src: require('@/assets/images/home/home-carouse3.png'),
          title: '协同低碳',
        },
      ],
      homeList: [
        {
          id: 1,
          src: require('@/assets/images/home/home-carousel1.png'),
          title: '专业的' + '<br>' + '碳中和数字化平台',
          url: 'enterpriseCarbon',
        },
        {
          id: 2,
          src: require('../assets/images/home/home-carousel2.png'),
          title: '高效、规模化的碳中和服务',
          url: 'meetingCarbon',
        },
        {
          id: 3,
          src: require('@/assets/images/home/home-carousel3.png'),
          title: '助力品牌可持续发展',
          url: 'reNewing',
        },
      ],
      newsbutton: [
        { id: 1, name: '公司新闻' },
        { id: 2, name: '行业新闻' },
      ],
      buttonindex: 0, //公司新闻和行业新闻
      routerButtonable: false, //路由按钮
      contentList: [],
    }
  },
  methods: {
    //公司新闻行业新闻切换
    newsbuttonClick(index) {
      this.buttonindex = index
      if (index == 0) {
        this.getComlist()
      }
      if (index == 1) {
        this.getguildlist()
      }
    },
    getComlist() {
      const host =
        location.origin.indexOf('localhost') > 0
          ? 'http://uat.tandidi.com'
          : location.origin
      axios.post(host + '/website/wxNews', { type: '1' }).then((res) => {
        this.contentList = res.data.data
        console.log(res.data.data)
      })
    },
    getguildlist() {
      const host =
        location.origin.indexOf('localhost') > 0
          ? 'http://uat.tandidi.com'
          : location.origin
      axios.post(host + '/website/wxNews', { type: '2' }).then((res) => {
        this.contentList = res.data.data
        console.log(res.data.data)
      })
    },
    routerClick(name) {
      this.$router.push(name)
    },
    contentToWx(item) {
      window.open(this.contentList[item].wx_url)
    },
    imgLoad() {
      this.$nextTick(() => {
        let ref = this.$refs
        this.bannerHeight =
          ref.bannerHeight && ref.bannerHeight.length
            ? ref.bannerHeight[0].height
            : ''
      })
    },
  },
  created() {
    this.getComlist()
  },
  mounted() {
    this.imgLoad()
    window.addEventListener(
      'resize',
      () => {
        this.imgLoad()
      },
      false
    )
  },
}
</script>

<style lang="less" scoped>
#home {
  margin: 0;
  padding: 0;
}
.left10 {
  left: 20%;
  position: absolute;
  top: 30%;

  line-height: 120px;
  font-size: 70px;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
}

.home-bigimg {
  position: relative;

  .text {
    position: absolute;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    top: 30%;
    width: 100%;
    line-height: 120px;
    font-size: 70px;
    font-weight: bold;
    color: #ffffff;
    opacity: 1;
  }
}
/deep/ .el-carousel__button {
  opacity: 0.8;
  &:active {
    opacity: 1;
  }
}

.flex-content-center {
  display: flex;
  justify-content: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-text {
  position: absolute;
  width: 60%;
  top: 25%;
  left: 20%;
  h3 {
    font-size: 38px;
    font-weight: 500;
    color: #ffffff;
    opacity: 1;
  }
}

.card-button {
  width: 128px;
  height: 38px;
  margin-top: 100px;
  border: 1px solid #ffffff;
  opacity: 1;
  background-color: transparent;
  border-radius: 22px;
  color: #ffffff;
  cursor: pointer;
}
.home-product {
  width: 50%;
  height: 800px;
  background: rgba(15, 120, 102, 1);
  opacity: 1;

  h1 {
    font-size: 44px;
    font-weight: 500;
    color: #ffffff;
    opacity: 1;
  }
  p {
    width: 554px;
    font-size: 18px;
    margin-top: 20px;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.7;
  }
}
.newsbutton {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
}
.activebutton {
  font-size: 38px;
  font-weight: 600;
  color: #3bb29c;
  opacity: 1;
}
.newsbox {
  width: 32%;
  margin-top: 30px;
  background: transparent;

  cursor: pointer;
  opacity: 1;
  border-radius: 12px;
  .newsbox-img {
    border-radius: 12px 12px 0 0;
    width: 100%;
  }
  .newsbox-content {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    border-radius: 0 0 12px 12px;

    .dateStr {
      position: relative;
      width: 120px;
      height: 52px;
      img {
        height: 52px;
      }
      span {
        position: absolute;
        left: 20px;
        top: 19px;
        font-size: 16px;
        color: #3bb29c;
      }
    }
    h3 {
      font-size: 18px;
      margin-left: 20px;
      margin-right: 20px;
      font-weight: 500;
      line-height: 34px;
      color: #333333;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;

      -webkit-box-orient: vertical;
    }
    p {
      font-size: 12px;
      margin-left: 20px;
      margin-right: 20px;
      color: #999999;
      line-height: 24px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      margin-bottom: 10px;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
