<template>
  <div class="tandidi-bottom">
    <div class="bottom">
      <div class="info">
        <div class="logo">
          <img
            src="@/assets/images/home/home-logo.png"
            alt=""
            style="width: 128px; height: 36px"
          />
        </div>
        <div class="text">
          <!-- <div class="tel">
            <img src="@/assets/images/home/bottom-mobile.png" alt="" />
            <span>021-64172892 &nbsp;/+86&nbsp;189-1809-6896</span>
          </div> -->
          <div class="area" style="visibility: hidden;">
            <img src="@/assets/images/home/bottom-address.png" alt="" />
            <span style="display: block; min-width: 300px"
              >上海市长宁区天山路1900号天杉德必易园920室</span
            >
          </div>
          <div class="email">
            <img src="@/assets/images/home/bottom-email.png" alt="" />
            <span>service@tandidi.com</span>
          </div>
          
        </div>
      </div>
      <div class="navigation">
        <div
          class="title"
          style="font-size: 16px; font-weight: 600; margin-bottom: 50px"
        >
          导航
        </div>
        <div class="links">
          <ul class="left">
            <li @click="routerClick({ name: 'meetingCarbon' })">
              会议/活动碳中和
            </li>
            <li @click="routerClick({ name: 'enterpriseCarbon' })">
              企业/机构碳中和
            </li>
            <li @click="routerClick({ name: 'exhibitionCarbon' })">
              展会碳中和
            </li>
          </ul>
          <ul class="center">
            <li @click="routerClick({ name: 'synergyCarbon' })">协同低碳</li>
            <li @click="routerClick({ name: 'productCarbon' })">产品碳中和</li>
            <li @click="routerClick({ name: 'reNewing' })">
              减排量和可再生产品
            </li>
          </ul>
          <ul class="right">
            <li @click="routerClick({ name: 'netProduct' })">
              碳低链及NFT产品发行
            </li>

            <li @click="routerClick({ name: 'newsTrends' })">新闻动态</li>
            <li style="visibility:hidden;" @click="routerClick({ name: 'aboutMe' })">关于我们</li>
          </ul>
        </div>
      </div>
      <div class="qrcodes">
        <div class="public" style="margin-right: 50px">
          <div class="img">
            <img src="@/assets/images/home/bottom-code1.png" alt="" />
          </div>
          <div class="text">碳低低微信公众号</div>
        </div>
        <div class="applet">
          <div class="img">
            <img src="@/assets/images/home/bottom-code2.png" alt="" />
          </div>
          <div class="text">碳低低微信小程序</div>
        </div>
      </div>
    </div>
    <div class="foot">
      <p>Copyright © 2022 <span style="display:none;">碳抵科技 版权所有</span> <a class="copya" href="https://beian.miit.gov.cn/" target="_blank">沪ICP备19019266号-5</a></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  mounted() {},
  methods: {
    routerClick(name) {
      this.$router.push(name)
      document.documentElement.scrollTop = 0
    },
  },
}
</script>

<style lang="less" scoped>
.tandidi-bottom {
  width: 100%;
  min-height: 330px;
  font-size: 14px;
  background-color: #0f7866;

  .bottom {
    width: 100%;
    height: 380px;
    padding: 0 90px 0 5%;
    display: flex;
    align-items: center;
    .info {
      width: 30%;
      .logo {
        width: 260px;
        margin-bottom: 70px;
      }
      .text {
        .email,
        .area {
          height: 40px;
          line-height: 30px;
          display: flex;
          align-items: center;
          img {
            width: 18px;
            margin-right: 12px;
          }
        }
      }
    }

    .navigation {
      width: 35%;
      .title {
      }
      .links {
        display: flex;
        .left,
        .center,
        .right {
          margin-right: 20px;
          color: #fff;
          li {
            line-height: 40px;
            list-style: none;
            &:hover {
              color: #34a994;
              cursor: pointer;
            }
          }
        }
        .right {
          margin: 0;
        }
      }
    }

    .qrcodes {
      width: 25%;
      display: flex;
      margin-left: 20px;
      .public,
      .applet {
        .img {
          margin-bottom: 20px;
          img {
            width: 120px;
            height: 120px;
          }
        }
        .text {
          color: #fff;
          text-align: center;
        }
      }
    }
  }
  .foot {
    margin-left: 10%;
    width: 80%;
    border-top: 1px solid #ffffff;
    text-align: center;
    height: 48px;
    line-height: 48px;
    p {
      font-size: 11px;

      color: #ffffff;
    }
  }
}

.copya{
  color:#fff;
}

.copya:hover{
  text-decoration: underline;
}
</style>
